import type { Order, ShippingMethod } from '@commercelayer/sdk'
import { intersect } from '@design-system/utils/array'

export const isHazmat = (method: ShippingMethod) => !!method.metadata?.hazmat
export const isPickUpInStore = (method: ShippingMethod) =>
  !!method.metadata?.pickupinstore

export const getShippingMethods = (order: Order) => {
  const shipments = order.shipments ?? []
  if (!shipments.length) return []

  const availableShippingMethodsPerShipments = shipments.map(
    s => s.available_shipping_methods ?? []
  )

  const availableForAllShippingMethods =
    availableShippingMethodsPerShipments.reduce(
      (availableForAllShippingMethods, shippingMethodsPerShipment) =>
        intersect(
          availableForAllShippingMethods,
          shippingMethodsPerShipment,
          (shippingMethod1, shippingMethod2) =>
            shippingMethod1.id === shippingMethod2.id
        )
    )

  const specificShippingMethods = availableForAllShippingMethods.filter(
    m => !isHazmat(m)
  )
  const specificShippingMethodsWithoutPickUpInStore =
    specificShippingMethods.filter(m => !isPickUpInStore(m))

  const availableShippingMethods =
    specificShippingMethodsWithoutPickUpInStore.length
      ? specificShippingMethods
      : availableForAllShippingMethods

  return availableShippingMethods
}

export const isShippingMethodFree = (
  order: Order,
  shippingMethod: Pick<
    ShippingMethod,
    'free_over_amount_float' | 'use_subtotal'
  >
) => {
  const isFreeOverAmountSpecified = isNonNullable(
    shippingMethod?.free_over_amount_float
  )

  if (!isFreeOverAmountSpecified) return false

  const cartValueToCompare = shippingMethod.use_subtotal
    ? order.subtotal_amount_float!
    : order.total_amount_with_taxes_float!

  const isCartValueOverFreeAmount =
    cartValueToCompare >= shippingMethod.free_over_amount_float!

  return isCartValueOverFreeAmount
}
