import type { DeliveryLeadTime } from '@commercelayer/sdk'

export function useDeliveryTimesLabel() {
  const { ts } = useI18n()

  /** Takes as input a DeliveryLeadTime object from CL and the i18n instance and returns the mapped label with the shipping method delivery times. */
  const getDeliveryLeadTimesLabel = (
    deliveryObject: DeliveryLeadTime | null | undefined
  ) => {
    if (deliveryObject?.min_days !== deliveryObject?.max_days)
      return ts('cartPage.recap.sideSheet.shipping.deliveryIn', {
        min: deliveryObject?.min_days ?? 0,
        max: deliveryObject?.max_days ?? 0,
      })
    else return ts('cartPage.recap.sideSheet.shipping.delivery')
  }

  return { getDeliveryLeadTimesLabel }
}
