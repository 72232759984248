import type { ProductCardHorizontalProps } from '@design-system/components/UI/UIProductCardHorizontal.props'
import type { Order, ShippingMethod } from '@commercelayer/sdk'
import {
  getShippingMethods,
  isShippingMethodFree,
} from '@design-system/utils/shippingMethods'

export const useCartUtilities = () => {
  const { ts } = useI18n()
  const { cart, couponLineItems, lineItems, lineItemsWithAlgoliaData } =
    useCart()
  const { getDeliveryLeadTimesLabel } = useDeliveryTimesLabel()
  const route = useRoute()
  const { formatPriceByLocale } = useFormatPrice()
  const { marketDefaultShippingMethod } = useShippingMethods()

  const maybeFormatPrice = (
    currency?: string | null,
    amountFloat?: number | null
  ) =>
    typeof currency === 'string' && typeof amountFloat === 'number'
      ? formatPriceByLocale(currency, amountFloat)
      : null

  const currency = computed(() => cart.value?.currency_code)

  const cartSubtotal = computed(() =>
    maybeFormatPrice(
      currency.value,
      lineItems.value?.reduce<number>((accumulator, current) => {
        return (
          accumulator +
          (current?.total_amount_float ?? 0) +
          (current?.discount_float ?? 0)
        )
      }, 0)
    )
  )

  const tax = computed(() =>
    maybeFormatPrice(
      currency.value,
      cart.value?.shipping_address ? cart.value?.total_tax_amount_float : 0
    )
  )

  const discount = computed(() =>
    maybeFormatPrice(
      currency.value,
      couponLineItems.value.reduce(
        (accumulator, lineItem) => accumulator + lineItem.total_amount_float,
        0
      )
    )
  )

  const estimatedTaxAmountFloat = ref<null | number>(null)
  const estimatedTax = computed(() =>
    maybeFormatPrice(currency.value, estimatedTaxAmountFloat.value)
  )

  const cartTotalFloat = computed(() => {
    if (!cart.value) return

    const isCheckout =
      typeof route?.name === 'string' && route.name.startsWith('checkout')

    const taxAmountFloat = !isCheckout
      ? (estimatedTaxAmountFloat.value ?? 0)
      : cart.value.shipping_address && !cart.value.tax_included
        ? cart.value.total_tax_amount_float!
        : 0

    const shippingAmountFloat = cart?.value?.shipments?.[0]?.shipping_method
      ? (cart.value.shipping_amount_float ?? 0)
      : (selectedShippingMethod.value?.costFloat ?? 0)

    const paymentAmountFloat = !isCheckout
      ? 0
      : (cart.value.payment_method_amount_float ?? 0)

    return (
      cart.value.subtotal_amount_float! +
      taxAmountFloat +
      cart.value.discount_amount_float! +
      shippingAmountFloat +
      paymentAmountFloat
    )
  })

  const cartTotal = computed(() => {
    return maybeFormatPrice(currency.value, cartTotalFloat.value)
  })

  const productsQuantity = computed(() => {
    return (
      lineItems.value?.reduce((acc, item) => (acc += item.quantity), 0) ?? 0
    )
  })

  const cartItems = computed<ProductCardHorizontalProps[]>(() => {
    return (
      lineItemsWithAlgoliaData.value?.map(lineItem => {
        const algoliaImageUrl = lineItem._algolia?.images
          ? lineItem._algolia.images.sort(
              (a, b) => (a.priority || 0) - (b.priority || 0)
            )[0]?.ImageURL
          : undefined

        return {
          id: lineItem.id,
          info: {
            name: lineItem._algolia?.Name ?? lineItem.name ?? '',
            price:
              lineItem.unit_amount_float! +
              lineItem.discount_float! / lineItem.quantity,
            oldPrice:
              lineItem.compare_at_amount_float !== lineItem.unit_amount_float ||
              lineItem.discount_float
                ? lineItem.compare_at_amount_float!
                : 0,
            percentageDiscount: percentageDiscount(
              lineItem.total_amount_float!,
              lineItem.total_amount_float! + lineItem.discount_float!
            ),
            color: lineItem._algolia?.ColorDesc ?? lineItem.metadata?.color,
            size: lineItem.metadata?.size,
          },
          quantity: lineItem.quantity,
          stockQty: lineItem.item.inventory?.quantity,
          itemTotalPrice:
            lineItem.total_amount_float + lineItem.discount_float!,
          available:
            lineItem.item.inventory?.available &&
            lineItem.item.inventory.quantity > 0,
          metadata: lineItem.metadata?.website,
          path: lineItem.path ?? '',
          image:
            algoliaImageUrl ??
            lineItem.image_url ??
            '/images/product-card-horizontal-placeholder.png',
          skuCode: lineItem.sku_code!,
        }
      }) ?? []
    )
  })

  const cartItemsCategories = computed(() => {
    const categories = lineItemsWithAlgoliaData.value
      ?.flatMap(lineItem => lineItem._algolia?.categoryPageId2)
      ?.filter(isNonNullable)
    return [...new Set(categories)]
  })

  const isAvailable = (item: ProductCardHorizontalProps) => {
    return (
      !!item.available &&
      !!item.quantity &&
      !!item.stockQty &&
      item.quantity <= item.stockQty
    )
  }

  const unavailableCartItemsIds = computed<string[]>(() =>
    cartItems.value.filter(item => !isAvailable(item)).map(item => item.id)
  )

  const getWebsite = (item: ProductCardHorizontalProps) => item.metadata

  const cartItemsByBrand = computed(() =>
    sortItemsByBrand(cartItems.value, getWebsite)
  )

  const availableShippingMethods = computed(() => {
    if (!cart.value) return []
    const availableShippingMethods = getShippingMethods(cart.value)
    return availableShippingMethods.map(getShippingMethod(cart.value))
  })

  const getShippingMethod = (order: Order) => (method: ShippingMethod) => {
    const delivery = method?.delivery_lead_time_for_shipment

    return {
      id: method?.id ?? '',
      type: method?.name ?? '',
      // String label shown on the shipping method option.
      // Property price_amount_for_shipment_float is not trustworthy,
      // so the shipping method price is checked frontend using the static shipping method price.
      cost: isShippingMethodFree(order, method)
        ? ts('free')
        : formatPriceByLocale(
            method.currency_code!,
            method.price_amount_float!
          ),
      // Float value used to calculate the total cart amount.
      costFloat: isShippingMethodFree(order, method)
        ? 0
        : method.price_amount_float!,
      description: getDeliveryLeadTimesLabel(delivery),
      metadata: method?.metadata,
    }
  }

  const marketDefaultShippingMethodPerOrder = computed(() => {
    if (!cart.value) return marketDefaultShippingMethod.value

    const isFree = isShippingMethodFree(
      cart.value,
      marketDefaultShippingMethod.value
    )

    return {
      ...marketDefaultShippingMethod.value,
      cost: isFree
        ? ts('free')
        : formatPriceByLocale(
            currency.value!,
            marketDefaultShippingMethod.value.costFloat
          ),
      costFloat: isFree ? 0 : marketDefaultShippingMethod.value.costFloat!,
    }
  })

  const defaultShippingMethod = computed(() => {
    return (
      availableShippingMethods.value?.find(method =>
        method.type.includes('Standard')
      ) ??
      availableShippingMethods.value?.[0] ??
      marketDefaultShippingMethodPerOrder.value
    )
  })

  const selectedShippingMethod = computed(
    () =>
      availableShippingMethods.value?.find(
        method => method.id === cart?.value?.shipments?.[0]?.shipping_method?.id
      ) ?? defaultShippingMethod.value
  )

  return {
    currency,
    cartItems,
    cartSubtotal,
    estimatedTaxAmountFloat,
    estimatedTax,
    tax,
    cartTotalFloat,
    cartTotal,
    productsQuantity,
    discount,
    cartItemsCategories,
    unavailableCartItemsIds,
    cartItemsByBrand,
    availableShippingMethods,
    defaultShippingMethod,
    selectedShippingMethod,
    maybeFormatPrice,
  }
}
